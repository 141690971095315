import { SEQUENTIALS_PER_PAGE } from "@smartrr/shared/entities/Sequential";
import { cloneDeep } from "lodash";
import { create } from "zustand";

import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { WithResult } from "../../../SequentialStore";

import {
  CombinedSequential,
  SequentialTransformationResult,
} from "@smartrr/shared/interfaces/sequential/misconfigured";

interface SequentialsStore {
  loading: boolean;
  sequentials: CombinedSequential[];
  count: number;
  pageNumber: number;
  groupId: string;

  actions: {
    get: (groupId: string) => Promise<void>;
    getMore: () => Promise<void>;
    update: (sequentials: CombinedSequential[]) => void;
    remove: (sequentialId: string) => void;
  };

  internal: {
    whileLoadingAsync: (fn: () => Promise<void>) => Promise<void>;
    fetchSequentials: () => Promise<WithResult<SequentialTransformationResult>>;
    resetGroup(groupId: string): void;
  };
}

const useSequentialsStore = create<SequentialsStore>()((set, get) => ({
  loading: false,
  sequentials: [],
  count: 0,
  pageNumber: -1,
  groupId: "",

  actions: {
    async get(groupId: string): Promise<void> {
      const internal = get().internal;
      const actions = get().actions;

      if (get().groupId !== groupId) {
        internal.resetGroup(groupId);
      }

      if (get().pageNumber === -1) {
        await actions.getMore();
      }
    },

    async getMore(): Promise<void> {
      await get().internal.whileLoadingAsync(async () => {
        const internal = get().internal;
        const actions = get().actions;

        if ((get().pageNumber + 1) * SEQUENTIALS_PER_PAGE > get().count) {
          return;
        }

        set({
          pageNumber: get().pageNumber + 1,
        });

        const sequentials = await internal.fetchSequentials();

        if (sequentials.result === "success") {
          actions.update(sequentials.data.data);
          set({
            count: sequentials.data.count,
          });
        }
      });
    },

    update(sequentials: CombinedSequential[]): void {
      const updatedSequentials = cloneDeep(get().sequentials);

      const newSequentials = sequentials.filter(newSequential => {
        for (let index = 0; index < updatedSequentials.length; index++) {
          if (updatedSequentials[index].id === newSequential.id) {
            updatedSequentials[index] = newSequential;
            return false;
          }
        }
        return true;
      });

      updatedSequentials.push(...newSequentials);

      set({
        sequentials: updatedSequentials,
      });
    },

    remove(sequentialId: string): void {
      set({
        sequentials: get().sequentials.filter(seq => seq.id !== sequentialId),
      });
    },
  },

  internal: {
    async whileLoadingAsync(fn: () => Promise<void>): Promise<void> {
      if (get().loading) {
        return;
      }

      set({
        loading: true,
      });

      await fn();

      set({
        loading: false,
      });
    },

    async fetchSequentials(): Promise<WithResult<SequentialTransformationResult>> {
      const data = await typedFrontendVendorApi.getReq("/sequentials", {
        query: {
          groupId: get().groupId,
          pageNumber: get().pageNumber,
        },
      });

      if (data.type !== "success") {
        return {
          result: "failure",
          message: data.message,
        };
      }

      return {
        result: "success",
        data: data.body,
      };
    },

    resetGroup(groupId: string): void {
      set({
        loading: false,
        sequentials: [],
        count: 0,
        pageNumber: -1,
        groupId,
      });
    },
  },
}));

export const SequentialsAccess = {
  useSequentials(): CombinedSequential[] {
    return useSequentialsStore(state => state.sequentials);
  },

  useLoading() {
    return useSequentialsStore(state => state.loading);
  },

  useActions() {
    return useSequentialsStore(state => state.actions);
  },

  useGetSequentialById(sequentialId: string): CombinedSequential | null {
    const sequentials = useSequentialsStore(state => state.sequentials);
    return (
      sequentials.find(seq => {
        return seq.id === sequentialId;
      }) ?? null
    );
  },
};
