import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";
import { SequentialsAccess } from "@vendor-app/app/_sharedComponents/Sequential/Information/ShowSequentials/SequentialsStore";
import { SequentialGroupContext } from "@vendor-app/app/_sharedComponents/Sequential/SequentialGroupContext";
import { useContext, useMemo } from "react";

export const useIsAddingNewPlanDisabled = () => {
  const { prepaidSequential } = useSmartrrFlags();
  const sequentials = SequentialsAccess.useSequentials();
  const { isPrepaid, numberOfPlans } = useContext(SequentialGroupContext);

  return useMemo(() => {
    if (!prepaidSequential || !isPrepaid) {
      return false;
    }
    return !!sequentials.length && numberOfPlans === 1;
  }, [prepaidSequential, sequentials, numberOfPlans, isPrepaid]);
};
