import { createContext } from "react";

export interface SequentialGroupContextType {
  numericShopifyId: number;
  isPrepaid: boolean;
  numberOfPlans: number;
  isCreatingNewProgram: boolean;
}

export const SequentialGroupContext = createContext<SequentialGroupContextType>({
  numberOfPlans: 0,
  numericShopifyId: 0,
  isPrepaid: false,
  isCreatingNewProgram: true,
});
