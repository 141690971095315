import { compact } from "lodash";
import { z } from "zod";

import { IPurchasable } from "../Purchasable";
import { ProductApi } from "../../interfaces/product/api";
import { VariantApi } from "../../interfaces/variant/api";
import { DEFAULT_VARIANT_TITLE } from "../../constants";
import { ensureShopifyGid } from "../../utils/ensureShopifyGid";

// Constants
export const SEQUENTIALS_PER_PAGE = 5;
export const MIN_SEQUENTIAL_ORDER_NUMBER = 1;

// Schema Definitions

// Sequential Action Schemas
export const SequentialSwapSchema = z.object({
  _actionType: z.literal("SWAP"),
  orderNumber: z.number().int().min(1, { message: "Order number starts at 1" }),
  vntToSwapTo: z.string().uuid({ message: "Invalid variant UUID" }),
});

export const SequentialRepeatSchema = z.object({
  _actionType: z.literal("REPEAT"),
  orderNumber: z.number().int().min(1, { message: "Order number starts at 1" }),
});

export const SequentialAddDiscountSchema = z.object({
  _actionType: z.literal("ADD_DISCOUNT"),
  orderNumber: z.number().int().min(1, { message: "Order number starts at 1" }),
  discountType: z.enum(["fixed_amount", "percentage"]),
  discountValue: z.number().min(0),
  forAllSubsequentOrders: z.boolean(),
});

export const SequentialEndSchema = z.object({
  _actionType: z.literal("END"),
  orderNumber: z.number().int().min(1, { message: "Order number starts at 1" }),
});

export const SequentialRestartSchema = z
  .object({
    _actionType: z.literal("RESTART"),
    orderNumber: z.number().int().min(1, { message: "Order number starts at 1" }),
    restartFromSequenceNumber: z.number().int().min(1, { message: "Can only restart from 1" }),
  })
  .refine(data => data.restartFromSequenceNumber <= data.orderNumber, {
    message: "restartFromSequenceNumber can't be greater than orderNumber",
    path: ["restartFromSequenceNumber"],
  });

export const SequenceActionSchema = z.union([
  SequentialSwapSchema,
  SequentialRepeatSchema,
  SequentialAddDiscountSchema,
  SequentialEndSchema,
  SequentialRestartSchema,
]);

// Sequential Schema
export const ISequenceSchema = z.array(SequenceActionSchema);

export const SequentialSchema = z.object({
  sequence: ISequenceSchema.min(1, { message: "Sequence should at least contain the end action" }),
  sellingPlanGroupId: z
    .string()
    .startsWith("gid://shopify/SellingPlanGroup", { message: "Must have gid://shopify/SellingPlanGroup prefix" }),
  initialVntId: z.string().uuid({ message: "Invalid variant UUID" }),
});

export type SequenceActionSchemaType = z.infer<typeof SequenceActionSchema>;
export type SequentialSchemaType = z.infer<typeof SequentialSchema>;

// Utility Functions

export const convertToSequentialProduct = (product: IPurchasable): ProductApi.Product => {
  const sequentialVariants = compact(
    (product?.vnts ?? []).map((vnt): VariantApi.Variant | null => {
      if (vnt.shopifyId) {
        return {
          createdDate: vnt.createdDate,
          deletedAt: vnt.deletedAt ?? null,
          id: vnt.id,
          images: [...(vnt.purchasableVariantImages ?? []), ...(product.purchasableImages ?? [])],
          name: vnt.purchasableVariantName ?? DEFAULT_VARIANT_TITLE,
          inventoryPolicy: vnt.inventoryPolicy ?? "CONTINUE",
          inventoryQuantity: vnt.inventoryQuantity ?? -1,
          isActiveInShopify: vnt.isActiveInShopify,
          isDraftOrArchived: vnt.isDraftOrArchived ?? false,
          presentmentPrices: vnt.presentmentPrices,
          receivedFromShopifyDate: vnt.receivedFromShopifyDate,
          requiresShipping: vnt.requiresShipping,
          shopifyId: vnt.shopifyId,
          shopifyInventoryId: vnt.shopifyInventoryId ?? ensureShopifyGid("InventoryItem", -1),
          shopifyStorefrontId: vnt.shopifyProductVariantStorefrontId ?? vnt.shopifyId,
          shopifyUpdateDate: vnt.shopifyUpdateDate,
          sku: vnt.sku ?? "",
          smallImages: [...(vnt.purchasableVariantSmallImages ?? []), ...(product.purchasableSmallImages ?? [])],
          updatedDate: vnt.updatedDate,
        };
      }
      return null;
    })
  );

  return {
    createdDate: product.createdDate,
    deletedAt: product.deletedAt ?? null,
    description: product.description,
    id: product.id,
    images: product.purchasableImages ?? [],
    isActiveInShopify: product.isActiveInShopify,
    isDraftOrArchived: product.isDraftOrArchived ?? false,
    name: product.purchasableName,
    receivedFromShopifyDate: product.receivedFromShopifyDate,
    shopifyId: product.shopifyId ?? ensureShopifyGid("Product", -1),
    shopifyStorefrontId:
      product.shopifyProductStorefrontId ?? product.shopifyId ?? ensureShopifyGid("Product", -1),
    shopifyUpdateDate: product.shopifyUpdateDate,
    smallImages: product.purchasableSmallImages ?? [],
    smartrrTags: product.smartrrTags ?? [],
    updatedDate: product.updatedDate,
    variants: sequentialVariants,
  };
};
