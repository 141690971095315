/**
 * Throw this in the default case of a switch statement to
 * assert its exhaustiveness.
 */
export default class UnreachableCaseError extends Error {
  constructor(value: never) {
    super(`Unreachable case: ${value}`);
    this.name = this.constructor.name;
    Error.captureStackTrace?.(this, this.constructor);
  }
}
